import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useAuth } from "../../utils/useAuth";
import DefaultButton from "../../components/Buttons/Default";
import AuthLayout from "../../components/Layouts/AuthLayout";
import RegistrationFieldHolder from "../../components/NoAccount/RegistrationFieldHolder";
import TextInput from "../../components/Inputs/TextInput";
import getSiteShortname from "../../utils/locales";
import strings from "../../strings";
import { errorParser } from "../../utils/errorHandling";

const LoginScreen: React.FC = () => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const path = getSiteShortname();

  const getLogo = () => {
    switch (path) {
      case "in":
        return "/logos/in.svg";
      default:
        return "/logos/uk.svg";
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await login(data.email, data.password);
      navigate("/");
    } catch (error: any) {
      errorParser(error, setError);
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className="h-full w-full flex items-start justify-between flex-col bg-gray-100">
        <div className="w-2/3 mx-auto pt-10">
          <img
            src={getLogo()}
            className="w-[200px]"
            alt={`${strings.title} Logo`}
          />
        </div>
        <div className="w-2/3 mx-auto">
          <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="font-medium text-gray-700 text-2xl mb-2">
              Welcome Back
            </h1>
            <p className="text-gray-500 mb-6">
              Please login using your registered details or click the sign up
              link to get started.
            </p>
            <RegistrationFieldHolder
              {...{
                control,
                errors,
                label: "Email address",
                id: "email",
                required: true,
              }}
              field={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.email}
                  placeholder="Enter your email address"
                  type="email"
                />
              )}
            />

            <RegistrationFieldHolder
              {...{
                control,
                errors,
                label: "Password",
                id: "password",
                required: true,
              }}
              field={({ field: { onChange, value } }: any): JSX.Element => (
                <TextInput
                  value={value}
                  onChange={onChange}
                  invalid={!!errors.password}
                  placeholder="Enter your password"
                  type="password"
                />
              )}
            />

            <div className="flex items-center justify-between mt-8 w-full">
              <DefaultButton
                title={loading ? "Loading..." : "Sign In"}
                type="submit"
              />
            </div>
          </form>

          <div className="flex items-center justify-center flex-col">
            <div className="mb-3">
              Don&lsquo;t have an account?{" "}
              <Link
                to="/register"
                className="font-medium text-green-600 hover:text-green-800 cursor-pointer"
              >
                Sign Up
              </Link>
            </div>

            <Link
              to="/forgot-password"
              className="font-medium text-green-600 hover:text-green-800 text-sm cursor-pointer"
            >
              Forgot Password?
            </Link>
          </div>
        </div>
        <div />
      </div>
    </AuthLayout>
  );
};

export default LoginScreen;
