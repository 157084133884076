import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import { useAuth } from "../../utils/useAuth";
import AuthLayout from "../../components/Layouts/AuthLayout";
import RegistrationFieldHolder from "../../components/NoAccount/RegistrationFieldHolder";
import TextInput from "../../components/Inputs/TextInput";
import DefaultButton from "../../components/Buttons/Default";
import getSiteShortname from "../../utils/locales";
import strings from "../../strings";

interface FormInputProps {
  name: string;
  email: string;
  password: string;
  password_confirmation: string;
}
const RegisterScreen: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const path = getSiteShortname();

  const getLogo = () => {
    switch (path) {
      case "in":
        return "/logos/in.svg";
      default:
        return "/logos/uk.svg";
    }
  };
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputProps>({
    defaultValues: {
      name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
  });

  const onSubmit = async (data: FormInputProps) => {
    setLoading(true);
    try {
      await auth.register(
        data.name,
        data.email,
        data.password,
        data.password_confirmation
      );
      navigate("/verify-email");
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert("Registration details are invalid.");
    }
    setLoading(false);
  };

  return (
    <AuthLayout width="1/2">
      <div className="h-full w-full flex items-start justify-between flex-col bg-gray-100">
        <div className="w-2/3 mx-auto pt-10">
          <img
            src={getLogo()}
            className="w-[200px]"
            alt={`${strings.title} Logo`}
          />
        </div>
        <div className="w-2/3 mx-auto">
          <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="font-medium text-gray-700 text-2xl mb-2">
              Create Account
            </h1>
            <p className="text-gray-500 mb-6">
              Please fill out your details in the form below to get started
              using {strings.title}.
            </p>

            <div className="grid grid-cols-2 gap-4 grid-rows-2 mt-10">
              <RegistrationFieldHolder
                {...{
                  control,
                  errors,
                  label: "Full Name",
                  id: "name",
                  required: true,
                }}
                field={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.name}
                    placeholder="Enter your full name"
                  />
                )}
              />
              <RegistrationFieldHolder
                {...{
                  control,
                  errors,
                  label: "Email address",
                  id: "email",
                  required: true,
                }}
                field={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.email}
                    placeholder="Enter your email address"
                    type="email"
                  />
                )}
              />

              <RegistrationFieldHolder
                {...{
                  control,
                  errors,
                  label: "Password",
                  id: "password",
                  required: true,
                }}
                field={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.password}
                    placeholder="Enter your password"
                    type="password"
                  />
                )}
              />
              <RegistrationFieldHolder
                {...{
                  control,
                  errors,
                  label: "Confirm Password",
                  id: "password_confirmation",
                  required: true,
                }}
                field={({ field: { onChange, value } }) => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.password_confirmation}
                    placeholder="Confirm your password"
                    type="password"
                  />
                )}
              />
            </div>

            <div className="flex items-center justify-between mt-8 w-full">
              <DefaultButton
                title={loading ? "Loading..." : "Sign In"}
                type="submit"
              />
            </div>
          </form>

          <div className="flex items-center justify-center flex-col">
            <div className="mb-3">
              Already have an account?{" "}
              <Link
                to="/"
                className="font-medium text-green-600 hover:text-green-800 cursor-pointer"
              >
                Log In
              </Link>
            </div>
          </div>
        </div>
        <div />
      </div>
    </AuthLayout>
  );
};

export default RegisterScreen;
