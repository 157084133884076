import React, { useState } from "react";
import ReactCodeInput from "react-code-input";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../../components/Buttons/Default";
import AuthLayout from "../../components/Layouts/AuthLayout";
import RegistrationFieldHolder from "../../components/NoAccount/RegistrationFieldHolder";
import strings from "../../strings";
import getSiteShortname from "../../utils/locales";
import { useAuth } from "../../utils/useAuth";

const codeInputProps = {
  inputStyle: {
    margin: "4px",
    width: "40px",
    borderRadius: "3px",
    display: "inline-flex",
    height: "40px",
    paddingLeft: "14px",
  },
};

const VerifyEmailScreen: React.FC = () => {
  const { verifyEmail, user, logout } = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const path = getSiteShortname();

  const getLogo = () => {
    switch (path) {
      case "in":
        return "/logos/in.svg";
      default:
        return "/logos/uk.svg";
    }
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    setError(false);
    try {
      await verifyEmail(data.code);
      navigate("/");
      window.location.reload();
    } catch (caughtError: unknown) {
      setError(true);
    }

    setLoading(false);
  };

  return (
    <AuthLayout>
      <div className="h-full w-full flex items-start justify-between flex-col bg-gray-100">
        <div className="w-2/3 mx-auto pt-10">
          <img
            src={getLogo()}
            className="w-[200px]"
            alt={`${strings.title} Logo`}
          />
        </div>
        <div className="w-2/3 mx-auto">
          <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
            <h1 className="font-medium text-gray-700 text-2xl mb-2">
              Welcome {user?.name}
            </h1>
            <p className="text-gray-500 mb-6">
              You need to verify your email address in order to continue, code
              has been sent to you. Please enter it and hit submit.
            </p>
            <RegistrationFieldHolder
              {...{
                control,
                errors,
                label: "Confirmation Code",
                id: "code",
                required: true,
              }}
              field={({ field: { onChange, value } }: any) => (
                // @ts-ignore
                <ReactCodeInput
                  name="code"
                  type="text"
                  fields={6}
                  value={value}
                  onChange={onChange}
                  inputMode="verbatim"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...codeInputProps}
                  className="w-full"
                />
              )}
            />

            {error && (
              <span className="text-sm block text-red-800 font-medium">
                Failed, the verification code you entered is invalid or has
                expired.
              </span>
            )}
            <div className="flex items-center justify-between mt-8 w-full">
              <DefaultButton
                title={loading ? "Loading..." : "Confirm"}
                type="submit"
              />
            </div>
          </form>

          <div className="flex items-center justify-center flex-col">
            <div className="mb-3 text-center border-b pb-3">
              Didn&lsquo;t receive a code?{" "}
              <div className="font-medium text-green-600 hover:text-green-800 cursor-pointer">
                Resend
              </div>
            </div>

            <div
              role="button"
              tabIndex={0}
              className="font-medium text-red-600 hover:text-red-800 text-sm cursor-pointer"
              onClick={() => logout()}
            >
              Logout
            </div>
          </div>
        </div>
        <div />
      </div>
    </AuthLayout>
  );
};

export default VerifyEmailScreen;
