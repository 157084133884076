import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth } from "../../utils/useAuth";
import AuthLayout from "../../components/Layouts/AuthLayout";
import getSiteShortname from "../../utils/locales";
import RegistrationFieldHolder from "../../components/NoAccount/RegistrationFieldHolder";
import strings from "../../strings";
import TextInput from "../../components/Inputs/TextInput";
import DefaultButton from "../../components/Buttons/Default";

const ForgotPassword = () => {
  const path = getSiteShortname();

  const { sendPasswordResetEmail } = useAuth();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      email: "test@domain.com",
    },
  });

  const onSubmit = async (data: any) => {
    setLoading(true);
    try {
      await sendPasswordResetEmail(data.email);
      setSuccess(true);
    } catch (error) {}
    setLoading(false);
  };

  const getLogo = () => {
    switch (path) {
      case "in":
        return "/logos/in.svg";
      default:
        return "/logos/uk.svg";
    }
  };

  return (
    <AuthLayout>
      <div className="h-full w-full flex items-start justify-between flex-col bg-gray-100">
        <div className="w-2/3 mx-auto pt-10">
          <img
            src={getLogo()}
            className="w-[200px]"
            alt={`${strings.title} Logo`}
          />
        </div>
        <div className="w-2/3 mx-auto">
          {success && (
            <div className="flex items-center justify-center flex-col mb-6">
              <p className="text-center text-2xl">
                Password reset email sent, please check your inbox and follow
                instructions.
              </p>
            </div>
          )}
          {!success && (
            <form className="pt-6 pb-8 mb-4" onSubmit={handleSubmit(onSubmit)}>
              <h1 className="font-medium text-gray-700 text-2xl mb-2">
                Forgot your password?
              </h1>
              <p className="text-gray-500 mb-6">
                Enter your email address bellow to receive instructions on how
                to reset your password
              </p>
              <RegistrationFieldHolder
                {...{
                  control,
                  errors,
                  label: "Email address",
                  id: "email",
                  required: true,
                }}
                field={({ field: { onChange, value } }: any): JSX.Element => (
                  <TextInput
                    value={value}
                    onChange={onChange}
                    invalid={!!errors.email}
                    placeholder="Enter your email address"
                    type="email"
                  />
                )}
              />

              <div className="flex items-center justify-between mt-8 w-full">
                <DefaultButton
                  title={loading ? "Loading..." : "Reset Password"}
                  type="submit"
                />
              </div>
            </form>
          )}

          <div className="flex items-center justify-center flex-col">
            <Link
              to="/"
              className="font-medium text-green-600 hover:text-green-800 text-sm cursor-pointer"
            >
              Return to login
            </Link>
          </div>
        </div>
        <div />
      </div>
    </AuthLayout>
  );
};

export async function getStaticProps() {
  return {
    props: {
      title: "Forgot Password",
    },
  };
}

export default ForgotPassword;
